import React, { useEffect, createRef } from "react"
import lottie from "lottie-web"
// import {
//   graphql,
//   useStaticQuery,
// } from "gatsby"

// Media Imports
import design from '../animations/design.json'

// Component Imports
import Layout from "../layout"

const Error = () => {
  // const data = useStaticQuery(
  //   graphql`
  //   query {
  //   }
  // `)

  let animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: design
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []); // eslint-disable-line

  return (
    <Layout>


      <section class="coming-soon">
        <div class="sw">
          <div class="content">
            <div class="animation-container" ref={animationContainer}></div>
            <div class="right">
              <h1>404 Error</h1>
              <h3>The page you requested could not be found.</h3>
            </div>
          </div>
        </div>
      </section>


    </Layout>
  )
}

export default Error